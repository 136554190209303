<template>
  <div
       class="ant-form-item-control has-error" style="line-height: 1.5;">
    <span class="ant-form-explain"><slot></slot></span>
  </div>
</template>

<script>


export default {
  name: "input-error-component",
};
</script>
