var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-in"},[_c('a-row',{staticClass:"row-main my-0",attrs:{"type":"flex","gutter":[24,24],"align":"middle"}},[_c('a-col',{staticClass:"col-form",attrs:{"span":24,"md":12,"lg":{span: 12, offset: 0},"xl":{span: 6, offset: 5}}},[_c('h4',{staticClass:"text-dark mb-15"},[_vm._v("Reset your password")]),_c('p',{staticClass:"text-muted"},[_vm._v("Enter your password reset code and your new password to continue")]),_c('a-form',{staticClass:"signup-form",attrs:{"id":"components-form-demo-normal-signup","form":_vm.form,"hideRequiredMark":true},on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword.apply(null, arguments)}}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Username","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'username',
					{ rules: [{ required: true, message: 'Please input your Username!' }] } ]),expression:"[\n\t\t\t\t\t'username',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your Username!' }] },\n\t\t\t\t\t]"}],attrs:{"type":"text","placeholder":"Username"},on:{"change":_vm.handleUsernameChange}}),(_vm.hasUsername && !_vm.$v.username.maxLength)?_c('input-error-component',[_vm._v(" Your Username is too long. ")]):_vm._e()],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"One-Time Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'code',
					{ rules: [{ required: true, message: 'Please input your OTP!' }] } ]),expression:"[\n\t\t\t\t\t'code',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your OTP!' }] },\n\t\t\t\t\t]"}],attrs:{"type":"text","placeholder":"One-Time Password"},on:{"change":_vm.handleCodeChange}}),(_vm.hasCode && !_vm.$v.code.maxLength)?_c('input-error-component',[_vm._v(" Your OTP is too long. ")]):_vm._e()],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'password',
					{ rules: [{ required: true, message: 'Please input your password!' }] } ]),expression:"[\n\t\t\t\t\t'password',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your password!' }] },\n\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Password"},on:{"change":_vm.handlePasswordChange}}),(!_vm.$v.password.maxLength)?_c('input-error-component',[_vm._v(" Your Password is too long. ")]):_vm._e()],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Confirm Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'confirm_password',
					{ rules: [{ required: true, message: 'Please confirm your password!' }] } ]),expression:"[\n\t\t\t\t\t'confirm_password',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Please confirm your password!' }] },\n\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Password Confirmation"},on:{"change":_vm.handlePasswordConfirmationChange}}),(_vm.hasConfirmPassword && _vm.hasPassword && !_vm.$v.confirm_password.sameAs)?_c('input-error-component',[_vm._v(" Your password confirmation does not match the password. ")]):_vm._e()],1),_c('a-form-item',[_c('a-button',{staticClass:"signup-form-button",attrs:{"disabled":_vm.$v.$invalid,"type":"primary","block":"","html-type":"submit"}},[_vm._v(" RESET PASSWORD ")])],1)],1),_c('p',{staticClass:"font-semibold text-muted text-center"},[_vm._v("I have not received a code yet. "),_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":{name: 'staffForgotPassword'}}},[_vm._v("Get Code")])],1),_c('p',{staticClass:"font-semibold text-muted"},[_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":{name: 'staffLogin'}}},[_vm._v("Click here to Login")])],1)],1),_c('a-col',{staticClass:"col-img",attrs:{"span":24,"md":12,"lg":12,"xl":12}},[_c('div',[_c('img',{attrs:{"src":"/images/img-signin.png"}})])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }