<!-- 
	This is the cover sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">
		
		<a-row type="flex" :gutter="[24,24]" align="middle" class="row-main my-0">

			<!-- Sign Up Form Column -->
			<a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 5}" class="col-form">
				
				<h4 class="text-dark mb-15">Reset your password</h4>
				<p class="text-muted">Enter your password reset code and your new password to continue</p>

				<!-- Sign Up Form -->
				<a-form
					id="components-form-demo-normal-signup"
					:form="form"
					class="signup-form"
					@submit.prevent="resetPassword"
					:hideRequiredMark="true"
				>
					<a-form-item class="mb-10" label="Username" :colon="false">
						<a-input @change="handleUsernameChange"
								 v-decorator="[
						'username',
						{ rules: [{ required: true, message: 'Please input your Username!' }] },
						]" type="text" placeholder="Username" />
						<input-error-component v-if="hasUsername && !$v.username.maxLength">
							Your Username is too long.
						</input-error-component>
					</a-form-item>
					<a-form-item class="mb-10" label="One-Time Password" :colon="false">
						<a-input @change="handleCodeChange"
								 v-decorator="[
						'code',
						{ rules: [{ required: true, message: 'Please input your OTP!' }] },
						]" type="text" placeholder="One-Time Password" />
						<input-error-component v-if="hasCode && !$v.code.maxLength">
							Your OTP is too long.
						</input-error-component>
					</a-form-item>
					<a-form-item class="mb-10" label="Password" :colon="false">
						<a-input @change="handlePasswordChange"
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: 'Please input your password!' }] },
						]" type="password" placeholder="Password" />
						<input-error-component v-if="!$v.password.maxLength">
							Your Password is too long.
						</input-error-component>
					</a-form-item>
					<a-form-item class="mb-10" label="Confirm Password" :colon="false">
						<a-input @change="handlePasswordConfirmationChange"
								 v-decorator="[
						'confirm_password',
						{ rules: [{ required: true, message: 'Please confirm your password!' }] },
						]" type="password" placeholder="Password Confirmation" />
						<input-error-component v-if="hasConfirmPassword && hasPassword && !$v.confirm_password.sameAs">
							Your password confirmation does not match the password.
						</input-error-component>


					</a-form-item>
					<a-form-item>
						<a-button :disabled="$v.$invalid" type="primary" block html-type="submit" class="signup-form-button">
							RESET PASSWORD
						</a-button>
					</a-form-item>
				</a-form>
				<!-- / Sign Up Form -->

			<p class="font-semibold text-muted text-center">I have not received a code yet. <router-link :to="{name: 'staffForgotPassword'}" class="font-bold text-dark">Get Code</router-link></p>
				<p class="font-semibold text-muted"><router-link :to="{name: 'staffLogin'}" class="font-bold text-dark">Click here to Login</router-link></p>
			</a-col>
			<!-- / Sign Up Form Column -->

			<!-- Sign Up Image Column -->
			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<!-- Sign Up Image -->
				<div>
					<img src="/images/img-signin.png">
				</div>
				
				<!-- / Sign Up Image -->
			</a-col>
			<!-- / Sign Up Image Column -->

		</a-row>
		
	</div>
</template>

<script>

    import CUSTOM_CONSTANTS from "@/constants";
	import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
	import InputErrorComponent from "@/app/components/ant-design/InputErrorComponent";

	export default ({
		components: {
            InputErrorComponent
		},
		data() {
			return {
				// Sign up form object.
				form: this.$form.createForm(this, { name: 'signup_cover' }),
				code: "",
				username: "",
				password: "",
				confirm_password: "",
			}
		},
        validations :{
            password:{
                required,
                minLength: minLength(CUSTOM_CONSTANTS.size.min.password),
                maxLength: maxLength(CUSTOM_CONSTANTS.size.max.password),
            },
            confirm_password:{
                required,
                sameAs:sameAs('password'),
            },
            code : {
                required,
                minLength: minLength(CUSTOM_CONSTANTS.size.min.otp),
                maxLength: maxLength(CUSTOM_CONSTANTS.size.max.otp),
            },
            username : {
                required,
                maxLength: maxLength(CUSTOM_CONSTANTS.size.max.name),
            }
        },
		mounted()
		{
		    if(this.$itemExists(this.$route.params)&&this.$itemExists(this.$route.params.code))
		    	this.code = this.$route.params.code
			else
			{
			    window.console.log("No Code, redirect to forgot password")
			}
		},
		methods: {
            handleCodeChange(e)
            {
                this.code = e.target.value;
            },
            handleUsernameChange(e)
            {
                this.username = e.target.value;
            },
            handlePasswordChange(e)
            {
                this.password = e.target.value;
            },
            handlePasswordConfirmationChange(e)
            {
                this.confirm_password = e.target.value;
            },
			// Handles input validation after submission.
            resetPassword() {
                window.console.log("Reset Password triggered")
                let _thisObj = this;
                _thisObj.form.validateFields((err, _values) => {
					if ( err || _thisObj.$v.$invalid )
					{
						return false;
					}
					/*console.log("Code: ", _thisObj.code)
                    console.log('Received values of form: ', _values) ;*/
                    _thisObj.$store.dispatch('REQUEST_EMPLOYEE_RESET_PASSWORD', {
                        username: _thisObj.username,
                        code: _thisObj.code,
						password: _values.password,
						password_confirmation: _values.confirm_password,
					})
                        .then(function(){
                            _thisObj.form.setFieldsValue({
								password: "",
                                confirm_password: "",
                            })
                            _thisObj.$store.dispatch("COMMIT_SET_MESSAGES",["Your password was successfully updated."]);
                            _thisObj.$router.push({name: "staffLogin"});
                        })
						.catch((e) => {
                        _thisObj.form.setFieldsValue({
                            /*password: "",*/
                            confirm_password: "",
						})
						});
				});
			},
		},
        computed: {
            hasUsername: function()
            {
                return typeof this.username !== "undefined" && this.username !== null && this.username.length > 0;
            },
            hasCode: function()
            {
                return typeof this.code !== "undefined" && this.code !== null && this.code.length > 0;
            },
            hasPassword: function()
            {
                return typeof this.password !== "undefined" && this.password !== null && this.password.length > 0;
            },
            hasConfirmPassword: function()
            {
                return typeof this.confirm_password !== "undefined" && this.confirm_password !== null && this.confirm_password.length > 0;
            },
        },
	})

</script>

<style lang="scss">
</style>